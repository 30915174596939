<template>
  <v-app>
    <side-menu v-if="authChecked && showSideMenu" />
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="#000000"
      dark
    >
      <header-item />
    </v-app-bar>
    <v-main>
      <v-container
        fluid
        :class="
          $route.name === 'login' ||
          $route.name === 'send_email' ||
          $route.name === 'password_reset'
            ? 'wide'
            : ''
        "
      >
        <snackbar />
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import HeaderItem from "./components/HeaderItem.vue";
import SideMenu from "./components/SideMenu.vue";
import Snackbar from "./components/Snackbar.vue";

export default {
  components: {
    SideMenu,
    HeaderItem,
    Snackbar,
  },

  data() {
    return {
      authChecked: false,
    };
  },

  computed: {
    showSideMenu() {
      return (
        !this.$route.meta.isPublic &&
        this.$store.getters["auth/me"].length !== 0 &&
        this.$store.getters["auth/client"].length !== 0
      );
    },
  },

  created() {
    if (this.$store.getters["auth/token"]) {
      Promise.all([
        this.$store.dispatch("auth/setMe"),
        this.$store.dispatch("auth/setClient"),
      ]).then(() => {
        this.authChecked = true;
      });
    } else {
      this.authChecked = true;
    }
  },
};
</script>

<style lang="scss">
@import "assets/scss/style";

html {
  /* font-size: 62.5%; */
}

body {
  position: relative;
  margin: 0;

  /* font-size: 1.4rem; */
  color: $textBaseColor;

  & * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

main {
  display: block;
}

@for $i from 1 through 6 {
  h#{$i} {
    margin: 0;
    font-size: 1.4rem;
    font-weight: normal;
  }
}

p {
  margin: 0;
}

a {
  display: inline-block;
  color: $textBaseColor;
  text-decoration: none;
  opacity: 1;
  transition: all 0.3s ease;
}

dl,
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

dd {
  margin: 0;
}

table {
  max-width: 100%;
  word-break: break-word;
  border-collapse: collapse;
}

figure {
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::-ms-expand {
    display: none;
  }
}

.contents_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.main {
  /* width: calc(100% - 15.6%); */
  min-height: calc(100vh - 6rem);
  padding: 2.2rem 4rem;
  &.wide {
    width: 100%;
  }
}
.photos {
  &__upload_area {
    width: 100%; // Required for IE11
    padding: 8.2rem 0 8.1rem;
    text-align: center;
    border: 0.1rem dotted #bcbcbc;

    input {
      display: none;
    }
  }
}
.Password {
  max-width: initial !important;
  margin: 0 !important;
  &__input {
    display: block;
    width: 100%;
    height: 56px;
    padding: 0 92px 0 12px;
    margin: 0 0 8px;
    border: 1px solid rgba(0, 0, 0, 0.42);
    border-radius: 4px;
  }
}
</style>
