const state = {
  countUpdateCategories: 0,
};

const mutations = {
  setCountUpdateCategories(state) {
    state.countUpdateCategories++;
  },
};

const actions = {
  setCountUpdateCategories({ commit }) {
    commit("setCountUpdateCategories");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
