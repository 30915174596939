import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import auth from "./auth";
import snackbar from "./snackbar";
import photo from "./photo";
import category from "./category";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  modules: {
    auth,
    snackbar,
    photo,
    category,
  },
  plugins: [
    createPersistedState({
      key: "photo-archive-admin",
      paths: ["auth.token", "auth.me", "auth.client"],
      storage: window.sessionStorage,
    }),
  ],
});
