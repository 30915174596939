const state = {
  message: "",
  color: "",
  timeout: -1,
};

const getters = {
  state(state) {
    return state;
  },
  existState(state) {
    return state.message !== "" && state.color !== "" && state.timeout !== "";
  },
};

const mutations = {
  setSnackbar(state, values) {
    state.message = values.message;
    state.color = values.color;
    state.timeout = values.timeout;
  },
  clearSnackbar(state) {
    state.message = "";
    state.color = "";
    state.timeout = -1;
  },
};

const actions = {
  setSnackbar(context, values) {
    context.commit("setSnackbar", values);
  },
  clearSnackbar(context) {
    context.commit("clearSnackbar");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
