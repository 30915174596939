const state = {
  uuid: "",
  faceIds: [],
  boundingPoly: [],
  isProcessingHighlight: false,
  isProcessingMosaic: false,
  mosaicImgFileUrl: "",
};

const mutations = {
  setUuid(state, uuid) {
    state.uuid = uuid;
  },
  setFaceIds(state, { faceIds, boundingPoly }) {
    state.faceIds = faceIds;
    state.boundingPoly = boundingPoly;
  },
  startHighlightPhoto(state) {
    state.isProcessingHighlight = true;
  },
  stopHighlightedPhoto(state) {
    state.isProcessingHighlight = false;
  },
  startMosaicPhoto(state) {
    state.isProcessingMosaic = true;
  },
  stopMosaicPhoto(state) {
    state.isProcessingMosaic = false;
  },
  setMosaicImgFileUrl(state, url) {
    state.mosaicImgFileUrl = url;
  },
};

const actions = {
  setUuid({ commit }, uuid) {
    commit("setUuid", uuid);
  },
  setFaceIds({ commit }, { faceIds, boundingPoly }) {
    commit("setFaceIds", { faceIds: faceIds, boundingPoly: boundingPoly });
  },
  startHighlightPhoto({ commit }) {
    commit("startHighlightPhoto");
  },
  stopHighlightedPhoto({ commit }) {
    commit("stopHighlightedPhoto");
  },
  startMosaicPhoto({ commit }) {
    commit("startMosaicPhoto");
  },
  stopMosaicPhoto({ commit }) {
    commit("stopMosaicPhoto");
  },
  setMosaicImgFileUrl({ commit }, url) {
    commit("setMosaicImgFileUrl", url);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
