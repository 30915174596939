<template>
  <v-snackbar
    v-model="snackbar"
    :color="props.color"
    :timeout="props.timeout"
    top
  >
    {{ props.message }}
  </v-snackbar>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      snackbar: false,
    };
  },
  computed: {
    isShow() {
      return this.existState();
    },
    props() {
      return this.state();
    },
  },
  watch: {
    isShow() {
      this.snackbar = this.existState();
    },
    snackbar() {
      if (!this.snackbar) {
        this.clearSnackbar();
      }
    },
  },
  methods: {
    ...mapGetters("snackbar", ["state", "existState"]),
    ...mapActions("snackbar", ["clearSnackbar"]),
  },
};
</script>
