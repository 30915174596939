import axios from "axios";
import store from "./store";

export const getAccessToken = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        process.env.VUE_APP_API_URL + "/oauth/token",
        Object.assign(payload, {
          grant_type: "password",
          client_id: process.env.VUE_APP_API_CLIENT_ID,
          client_secret: process.env.VUE_APP_API_CLINET_SECRET,
          scope: "*",
        })
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          store.dispatch("auth/logout");
        }
        reject(error);
      });
  });
};

export const getData = (target, params = null) => {
  const p = (t) => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_API_URL + "/api/admin/" + t, {
          headers: {
            Authorization: `Bearer ${store.getters["auth/token"]}`,
          },
          params,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            store.dispatch("auth/logout");
          }
          reject(error);
        });
    });
  };
  if (typeof target === "string") {
    return p(target);
  } else if (Array.isArray(target)) {
    return Promise.all(target.map((t) => p(t)));
  } else {
    return new Promise((resolve, reject) => {
      reject("target is not valid format");
    });
  }
};

export const createData = (target, payload, extraHeader = null) => {
  const requestHeader = {
    headers: {
      Authorization: `Bearer ${store.getters["auth/token"]}`,
    },
  };

  return new Promise((resolve, reject) => {
    axios
      .post(
        process.env.VUE_APP_API_URL + "/api/admin/" + target,
        payload,
        Object.assign(requestHeader, extraHeader)
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          store.dispatch("auth/logout");
        }
        reject(error);
      });
  });
};

export const updateData = (target, payload, extraHeader = null) => {
  // corsの関係でpostを使用し、headersに'X-HTTP-Method-Override': 'PUT'を追加
  const requestHeader = {
    headers: {
      Authorization: `Bearer ${store.getters["auth/token"]}`,
      "X-HTTP-Method-Override": "PUT",
    },
  };

  return new Promise((resolve, reject) => {
    // axios.put(process.env.VUE_APP_API_URL + target, payload, Object.assign(requestHeader, extraHeader))
    axios
      .post(
        process.env.VUE_APP_API_URL + "/api/admin/" + target,
        payload,
        Object.assign(requestHeader, extraHeader)
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          store.dispatch("auth/logout");
        }
        reject(error);
      });
  });
};

export const deleteData = (target) => {
  const requestHeader = {
    headers: {
      Authorization: `Bearer ${store.getters["auth/token"]}`,
    },
  };

  return new Promise((resolve, reject) => {
    axios
      .delete(
        process.env.VUE_APP_API_URL + "/api/admin/" + target,
        requestHeader
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          store.dispatch("auth/logout");
        }
        reject(error);
      });
  });
};

export const startDetections = async (file) => {
  try {
    await store.dispatch("photo/startHighlightPhoto");
    const data = new FormData();
    data.append("file", file);
    const response = await axios.post(
      `${process.env.VUE_APP_RECOGNITION_API_URL}/vision-detections`,
      data
    );
    const uuid = response.data.uuid;
    await store.dispatch("photo/setUuid", uuid);
    const polling = async (uuid, time) => {
      const timer = setTimeout(() => polling(uuid, time), time);
      const result = await axios.get(
        `${process.env.VUE_APP_RECOGNITION_API_URL}/vision-detections/${uuid}`
      );
      const error = typeof result.data.error !== "undefined";
      console.log(error);
      if (!error) {
        clearTimeout(timer);
        let faceIds = [];
        let boundingPoly = [];
        /* cloud vision使用時 */
        const obj = result.data.vision_detections.faceAnnotations;
        Object.keys(obj).forEach((key) => {
          faceIds.push(obj[key].face_id);
          const v = obj[key].boundingPoly.vertices.map((item) => {
            return {
              x: item.x !== undefined ? item.x : 0,
              y: item.y !== undefined ? item.y : 0,
            };
          });
          const rect = {
            x: v[0].x,
            y: v[0].y,
            width: v[1].x - v[0].x,
            height: v[3].y - v[0].y,
          };
          boundingPoly.push(rect);
        });
        /* darknet使用時 */
        // const obj = result.data.darknet_detections;
        // Object.keys(obj).forEach((key) => {
        //   faceIds.push(obj[key].face_id);
        //   boundingPoly.push(obj[key].vertices);
        // });
        await store.dispatch("photo/setFaceIds", {
          faceIds: faceIds,
          boundingPoly: boundingPoly,
        });
        await store.dispatch("photo/stopHighlightedPhoto");
      }
    };
    await polling(uuid, 5000);
  } catch (error) {
    console.error(error);
  }
};

export const createMosaic = async (uuid, faceIds) => {
  try {
    await store.dispatch("photo/startMosaicPhoto");
    const data = JSON.stringify({ face_ids: faceIds });
    await axios({
      method: "post",
      url: `${process.env.VUE_APP_RECOGNITION_API_URL}/vision-detections/${uuid}/mosaic-faces`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    });
    const polling = async (uuid, time) => {
      const timer = setTimeout(() => polling(uuid, time), time);
      const result = await axios.get(
        `${process.env.VUE_APP_RECOGNITION_API_URL}/vision-detections/${uuid}/mosaic-faces`,
        { responseType: "blob" }
      );
      const error = typeof result.data.error !== "undefined";
      console.log(error);
      if (!error) {
        clearTimeout(timer);
        const blob = new Blob([result.data]);
        const url = window.URL.createObjectURL(blob);
        await store.dispatch("photo/setMosaicImgFileUrl", url);
        await store.dispatch("photo/stopMosaicPhoto");
      }
    };
    setTimeout(() => polling(uuid, 5000), 2000);
  } catch (error) {
    console.log(error);
  }
};
